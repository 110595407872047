import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Modal as AntModal, Select } from "antd";
import Modal from "react-bootstrap/Modal";
import { editContactByAdmin } from "../../redux/actions";
import DotLoader from "../../hoc/DotLoader";
import { verifyMail } from "../../utils/loginAuth";
import { ContentState, EditorState, convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";
import TextEditor from "../../hoc/textEditor/TextEditor";
import htmlToDraft from "html-to-draftjs";
const { Option } = Select

const EditContact = ({
    showEditModal,
    setEditShowModal,
    editContactByAdmin,
    loading,
    search,
    pageNo,
    count,
    state,
    sortField, sortType, searchMonth, searchYear, AddedBy

}) => {
    const [email, setEmail] = useState("");
    const [name, setName] = useState("");
    const [addedBy, setAddedBy] = useState("")
    const [contactNumber, setContactNumber] = useState("");
    const [projectDetail, setProjectDetail] = useState(EditorState.createEmpty())
    const [uploadFile, setUploadFile] = useState("");

    const [minBudget, setMinBudget] = useState("")
    const [maxBudget, setMaxBudget] = useState("")

    const [minBudgetErrorMessage, setMinBudgetErrorMessage] = useState("")
    const [maxBudgetErrorMessage, setMaxBudgetErrorMessage] = useState("")
    const [errorText, setErrorText] = useState("");
    const [emailErrorText, setEmailErrorText] = useState("");
    const [nameErrorMessage, setNameErrorMessage] = useState("")
    const [projectDetailErrorMessage, setProjectDetailErrorMessage] = useState("")
    const [loader, setLoader] = useState(false)
    const [resMsg, setResMsg] = useState(false);
    const [contactNumberErrorMsg, setContactNumberErrorMsg] = useState("")

    const [resFailMsg, setFailResMsg] = useState(false);
    const [comment, setComment] = useState(EditorState.createEmpty())
    const [contactStatus, setContactStatus] = useState(0)
    const [contactResult, setContactResult] = useState(0)
    const RegExp1 = /^[0-9]+$/;
    const [hear, setHear] = useState(EditorState.createEmpty())

    const handleDraftToHtml = (props) => {
        let htmlContent = props
        let wrappedHtmlContent = htmlContent?.toString().includes("<p>") ? htmlContent : `<p>${htmlContent?.replaceAll("<br />", "")}</p>`
        const contentBlock = htmlToDraft(wrappedHtmlContent)
        const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks)
        const _editorState = EditorState.createWithContent(contentState)
        return _editorState
    }
    useEffect(() => {
        setName(state.name);
        setEmail(state.email)
        setContactNumber(state.contactNumber)
        setProjectDetail(handleDraftToHtml(state?.projectDetails))
        setUploadFile(state?.fileLinks)
        setAddedBy(state.addedBy)
        setMinBudget(state.projectAmountMin == null ? "" : state.projectAmountMin)
        setMaxBudget(state.projectAmountMax == null ? "" : state.projectAmountMax)
        setComment(state.comment ? handleDraftToHtml(state?.comment) : EditorState.createEmpty())
        setHear(state.hear ? handleDraftToHtml(state?.hear) : EditorState.createEmpty())
        setContactStatus(state.contactStatus ? state?.contactStatus : 0)
        setContactResult(state.contactResult ? state?.contactResult : 0)
    }, [showEditModal, state])


    const handleCancelButtonClick = () => {
        setEmail("");
        setEmailErrorText("")
        setContactNumberErrorMsg()
        setName("");
        setNameErrorMessage("");
        setContactNumber("");
        setProjectDetail(EditorState.createEmpty());
        setProjectDetailErrorMessage("");
        setErrorText("");
        setEmailErrorText("");
        setMinBudget("");
        setMaxBudget("");
        setMaxBudgetErrorMessage("");
        setMinBudgetErrorMessage("");
        setLoader(false)
        setEditShowModal(false);
        setComment(EditorState.createEmpty());
        setContactResult(0);
        setContactStatus(0);
    };




    const handleMinBudgetValidation = () => {
        let validate = true;
        const RegExp = /^\d*\.?\d{0,2}$/;
        if (RegExp.test(minBudget) == false) {
            validate = false;
        }
        else {
        }
        return validate
    }

    const handleMaxBudgetValidation = () => {
        let validate = true;
        const RegExp = /^\d*\.?\d{0,2}$/;
        if (Number(maxBudget) < Number(minBudget)) {
            validate = false;

        }
        else if (RegExp.test(maxBudget) == false) {
            validate = false;

        }
        else {

        }
        return validate
    }


    const handleNameValidation = () => {
        let validate = true;
        let NameRegExp = /^[a-zA-Z]{3,}(?: [a-zA-Z]+){0,2}$/
        if (name === "") {
            validate = false;
        }
        else if (!NameRegExp.test(name)) {
            validate = false
        }
        else {
        }
        return validate
    }
    const handleProjectDetailValidation = () => {
        let validate = true;
        if (projectDetail === "") {
            validate = false;
        }
        else {
        }
        return validate
    }

    const handleContactValidation = () => {
        let validate = true;
        if (RegExp1.test(contactNumber) == false && contactNumber !== "") {
            validate = false;
        }
        else {
        }
        return validate
    }

    const onSubmitClick = async () => {
        if (validation()
        ) {
            setLoader(true)
            const data = new FormData();
            data.append("email", email);
            data.append("name", name);
            data.append("addedBy", addedBy)
            data.append("contactNumber", contactNumber);
            data.append("projectAmountMin", minBudget);
            data.append("projectAmountMax", maxBudget);
            data.append("projectDetails", projectDetail ? draftToHtml(convertToRaw(projectDetail.getCurrentContent())) : "");
            data.append("contactId", state._id)
            data.append("docs", uploadFile);
            data.append("comment", comment ? draftToHtml(convertToRaw(comment.getCurrentContent())) : "")
            data.append("hear", hear ? draftToHtml(convertToRaw(hear.getCurrentContent())) : "")
            data.append("contactStatus", contactStatus)
            data.append("contactResult", contactResult)
            const query = `search=${search}&pageNo=${pageNo - 1}&count=${count}&sortType=${sortType}&sortField=${sortField}&searchYear=${searchYear}&searchMonth=${searchMonth}&filterBy=${AddedBy}`;
            await editContactByAdmin(data, query, setEditShowModal, setErrorText, setResMsg, setFailResMsg, handleCancelButtonClick, setLoader);
        }
        else {
        }

        setTimeout(() => setResMsg(false), 4000)
        setTimeout(() => setFailResMsg(false), 4000)
    };
    const handleNameChange = (param) => {
        setName(/^\s/.test(param.target.value) ? "" : param.target.value);
        setNameErrorMessage("");
    };
    const handleContactNumberChange = (param) => {
        setContactNumber(/^\s/.test(param.target.value) ? "" : param.target.value);
        setContactNumberErrorMsg("")

    };
    const handleEmailChange = (param) => {
        setEmail(/^\s/.test(param.target.value) ? "" : param.target.value);
        setEmailErrorText("");
    };
    const handleUploadFileChange = (param) => {
        setUploadFile(param.target.files[0]);
    };
    const handleProjectDetailChange = (param) => {
        setProjectDetail(param);
    };
    const handleMinBudgetChange = (param) => {
        setMinBudget(param.target.value);
        setMinBudgetErrorMessage("");
    };
    const handleMaxBudgetChange = (param) => {
        setMaxBudget(param.target.value);
        setMaxBudgetErrorMessage("");
    };

    const handleCommentChange = (e) => {
        setComment(e);
    };
    const handlehearChange = (e) => {
        setHear(e);
    }
    const handleContactStatusChange = (e) => {
        setContactStatus(e)
    }
    const handleContactResultChange = (e) => {
        setContactResult(e)
    }

    const validation = () => {
        const NameRegExp = /^[a-zA-Z]{3,}(?: [a-zA-Z]+){0,2}$/;
        let k = convertToRaw(projectDetail.getCurrentContent())

        let validate = true
        if (verifyMail(email) !== 0) {
            validate = false
            setEmailErrorText("Enter a valid email");
        } else {
            setEmailErrorText("");
        }
        if (name == "") {
            validate = false

            setNameErrorMessage("Enter Name")
        }
        // else if (!NameRegExp.test(name)) {
        //     validate = false

        //     setNameErrorMessage("Please Enter Valid Name")
        // }
        else {
            setNameErrorMessage("")
        }
        if (k.blocks.every(b => b.text.trim() === '')) {
            validate = false
            setProjectDetailErrorMessage("Please Enter Project Detail");
        }
        else {
            setProjectDetailErrorMessage("")
        }

        const RegExp = /^\d*\.?\d{0,2}$/;
        if (RegExp.test(minBudget) == false) {
            validate = false
            setMinBudgetErrorMessage("you can enter only positive digit and two digit after Decimal")
        }
        else {
            setMinBudgetErrorMessage("");
        }
        if (Number(maxBudget) < Number(minBudget)) {
            validate = false
            setMaxBudgetErrorMessage('Max budget can not be less then min budget')
        }
        else if (RegExp.test(maxBudget) == false) {
            validate = false
            setMaxBudgetErrorMessage("you can enter only positive digit and two digit after Decimal")
        }
        else {
            setMaxBudgetErrorMessage("");
        }
        // if (RegExp1.test(contactNumber) == false && contactNumber !== "") {
        //     validate = false
        //     setContactNumberErrorMsg("Please Enter Number Only")
        // }
        // else {
        //     setContactNumberErrorMsg("");
        // }
        return validate
    };
    return (
        <AntModal
            centered
            className="attend_modal"
            footer={false}
            visible={showEditModal}
            onCancel={handleCancelButtonClick}
        >
            <Modal.Body>
                <form
                    onSubmit={(e) => {
                        e.preventDefault();
                        onSubmitClick();
                    }}
                >
                    <h3 className="mb-4">Edit Contact</h3>
                    {
                        resMsg ?
                            <div className="alert alert-success cm_top_fix"> Contact Edited Successfully </div> : ''
                    }
                    {
                        resFailMsg ?
                            <div className="alert alert-danger cm_top_fix"> Please Try Again </div> : ''
                    }
                    <div className="row">
                        <div className="col-lg-6 col-md-6 mb-3">
                            <label className="cm_bold">Client Name</label>
                            <input
                                maxLength="60"
                                className="form-control cm_box"
                                placeholder="Enter Name"
                                type="text"
                                value={name}
                                onChange={handleNameChange}
                            />
                            <span className="errorText">{nameErrorMessage}</span>

                        </div>
                        <div className="col-lg-6 col-md-6 mb-3">
                            <label className="cm_bold">Contact Number</label>
                            <input
                                maxLength="18"
                                className="form-control cm_box"
                                placeholder="Enter Contact Number"
                                type="text"
                                value={contactNumber}
                                onChange={handleContactNumberChange}
                            />
                            <span className="errorText">{contactNumberErrorMsg}</span>

                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-6 col-md-6 mb-3">
                            <label className="cm_bold">Email</label>
                            <input
                                maxLength="60"
                                className="form-control cm_box"
                                placeholder="Please Enter Valid Email"
                                type="text"
                                value={email}
                                onChange={handleEmailChange}
                            />
                            <span className="errorText">{emailErrorText}</span>
                        </div>
                        <div className="col-lg-6 col-md-6 mb-3">
                            <label className="cm_bold">Budget ($)</label>
                            <input
                                maxLength="60"
                                className="form-control cm_box mb-3"
                                placeholder="Please Enter Min Amount"
                                type="text"
                                value={minBudget}
                                onChange={handleMinBudgetChange}
                            />
                            <span className="errorText">{minBudgetErrorMessage}</span>

                            <input
                                maxLength="60"
                                className="form-control cm_box"
                                placeholder="Please Enter Max Amount"
                                type="text"
                                value={maxBudget}
                                onChange={handleMaxBudgetChange}
                            />
                            <span className="errorText">{maxBudgetErrorMessage}</span>

                        </div>
                    </div>
                    <div className="row">
                        <div
                            className={`form-group col-lg-6 col-md-6 mb-3 `}
                        >
                            <label className="cm_bold">Contact Result</label>

                            <Select
                                name="isAvailedPF"
                                value={contactResult ? contactResult : "Result"}
                                onChange={handleContactResultChange}
                            >
                                <Option value={1}>Win</Option>
                                <Option value={2}>Loss</Option>
                                <Option value={3}>Contact In Future</Option>
                                <Option value={4}>Connection Not Established</Option>
                            </Select>
                        </div>

                        <div
                            className={`form-group col-lg-6 col-md-6 mb-3 `}
                        >
                            <label className="cm_bold">Contact Status</label>

                            <Select
                                name="isAvailedPF"
                                value={contactStatus ? contactStatus : "Status"}
                                onChange={handleContactStatusChange}
                            >
                                <Option value={1}>Hot</Option>
                                <Option value={2}>Warm</Option>
                                <Option value={3}>Cold</Option>
                                <Option value={4}>Not Relevant</Option>


                            </Select>
                        </div>
                    </div>

                    <div className="row">
                        <div className="form-group col-lg-12 col-md-12 mb-3">
                            <label className="cm_bold">Project Details</label>
                            {/* <textarea
                                maxLength="500"
                                className="form-control cm_box"
                                placeholder="Enter Project Detail"
                                type="text"
                                rows={5}
                                value={projectDetail}
                                onChange={handleProjectDetailChange}
                            /> */}
                            <TextEditor
                                editorState={projectDetail}
                                onEditorStateChange={handleProjectDetailChange}
                            />
                            <span className="errorText">{projectDetailErrorMessage}</span>

                        </div>
                    </div>

                    <div className="row">
                        <div className="form-group col-lg-12 col-md-12 mb-3">
                            <label className="cm_bold">Comment</label>

                            <TextEditor
                                editorState={comment}
                                onEditorStateChange={handleCommentChange}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="form-group col-lg-12 col-md-12 mb-3">
                            <label className="cm_bold">Hear</label>

                            <TextEditor
                                editorState={hear}
                                onEditorStateChange={handlehearChange}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="form-group col-lg-12 col-md-12 mb-3">
                            <label className="sub" for="upload-files">
                                Upload File
                            </label>
                            <span className="form-control cm_hide_upload">
                                <i className="fa fa-upload" aria-hidden="true"></i>
                                <span className="cm_upload_name">
                                    {uploadFile ? uploadFile.name : "Upload file"}
                                </span>
                                <input
                                    type="file"
                                    id="file"
                                    multiple
                                    accept="application/pdf,.doc, .docx"
                                    onChange={handleUploadFileChange}
                                />

                            </span>
                        </div>

                        <div className="addCandidateButtonsDiv">
                            <button
                                onClick={handleCancelButtonClick}
                                type="button"
                                className="cancel_btn btn  buttonWidth"
                            >
                                Cancel
                            </button>
                            {loader ? <DotLoader /> :
                                <button className="btn btn-primary  buttonWidth" type="submit">
                                    Submit
                                </button>}
                        </div>
                    </div>
                </form>
            </Modal.Body>
        </AntModal>
    );
};
const mapStateToProps = (state) => {
    return {
        loading: state.editCandidateByAdmin.loading,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        editContactByAdmin: (data, query, setEditShowModal, setErrorText, setResMsg, setFailResMsg, handleCancelButtonClick, setLoader) => {
            dispatch(editContactByAdmin(data, query, setEditShowModal, setErrorText, setResMsg, setFailResMsg, handleCancelButtonClick, setLoader));
        },
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(EditContact);
