import React, { useState } from "react";
import { connect } from "react-redux";
import { Modal as AntModal, Select } from "antd";
import Modal from "react-bootstrap/Modal";
import { addContactByAdmin } from "../../redux/actions";
import DotLoader from "../../hoc/DotLoader";
import { verifyMail } from "../../utils/loginAuth";
import { EditorState, convertToRaw } from "draft-js";
import TextEditor from "../../hoc/textEditor/TextEditor";
import draftToHtml from "draftjs-to-html";
const { Option } = Select;

const AddContact = ({
  showModal,
  addContactByAdmin,
  setShowModal,
  loading,
  search,
  pageNo,
  count,
  sortField,
  sortType,
  searchMonth,
  searchYear,
  AddedBy,
}) => {
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [contactNumber, setContactNumber] = useState("");
  const [projectDetail, setProjectDetail] = useState(EditorState.createEmpty());
  const [uploadFile, setUploadFile] = useState("");

  const [minBudget, setMinBudget] = useState("");
  const [maxBudget, setmaxBudget] = useState("");

  const [minBudgetErrorMessage, setMinBudgetErrorMessage] = useState("");
  const [maxBudgetErrorMessage, setMaxBudgetErrorMessage] = useState("");
  const [errorText, setErrorText] = useState("");
  const [emailErrorText, setEmailErrorText] = useState("");
  const [nameErrorMessage, setNameErrorMessage] = useState("");
  const [projectDetailErrorMessage, setProjectDetailErrorMessage] =
    useState("");
  const [loader, setLoader] = useState(false);
  const [resMsg, setResMsg] = useState(false);
  const [resFailMsg, setFailResMsg] = useState(false);
  const [contactNumberErrorMsg, setContactNumberErrorMsg] = useState("");
  const [comment, setComment] = useState(EditorState.createEmpty());
  const [contactStatus, setContactStatus] = useState(0);
  const [contactResult, setContactResult] = useState(0);
  const [hear, setHear] = useState(EditorState.createEmpty())
  const RegExp1 = /^[0-9]+$/;

  const handleCancelButtonClick = () => {
    setEmail("");
    setEmailErrorText("");
    setName("");
    setNameErrorMessage("");
    setContactNumber("");
    setProjectDetail(EditorState.createEmpty());
    setHear(EditorState.createEmpty());
    setProjectDetailErrorMessage("");
    setErrorText("");
    setEmailErrorText("");
    setMinBudget("");
    setmaxBudget("");
    setUploadFile("");
    setMaxBudgetErrorMessage("");
    setMinBudgetErrorMessage("");
    setContactNumberErrorMsg("");
    setLoader(false);
    setShowModal(false);
    setComment(EditorState.createEmpty());
    setContactResult(0);
    setContactStatus(0);
  };

  const onSubmitClick = async () => {
    if (validation()) {
      setLoader(true);
      const data = new FormData();
      data.append("email", email);
      data.append("name", name);
      data.append("contactNumber", contactNumber);
      data.append("addedBy", 2);
      data.append("projectAmountMin", minBudget);
      data.append("projectAmountMax", maxBudget);
      data.append(
        "projectDetails",
        projectDetail
          ? draftToHtml(convertToRaw(projectDetail.getCurrentContent()))
          : ""
      );
      data.append(
        "hear",
        hear
          ? draftToHtml(convertToRaw(hear.getCurrentContent()))
          : ""
      );
      data.append("docs", uploadFile);
      data.append(
        "comment",
        comment ? draftToHtml(convertToRaw(comment.getCurrentContent())) : ""
      );
      data.append("contactStatus", contactStatus);
      data.append("contactResult", contactResult);
      const query = `search=${search}&pageNo=${
        pageNo - 1
      }&count=${count}&sortType=${sortType}&sortField=${sortField}&searchYear=${searchYear}&searchMonth=${searchMonth}&filterBy=${AddedBy}`;
      await addContactByAdmin(
        data,
        query,
        setShowModal,
        setErrorText,
        setResMsg,
        setFailResMsg,
        handleCancelButtonClick,
        setLoader
      );
    } else {
    }

    setTimeout(() => setResMsg(false), 4000);
    setTimeout(() => setFailResMsg(false), 4000);
  };

  const handleNameChange = (param) => {
    setName(/^\s/.test(param.target.value) ? "" : param.target.value);
    setNameErrorMessage("");
  };

  const handleMinBudgetChange = (param) => {
    setMinBudget(param.target.value);
    setMinBudgetErrorMessage("");
  };
  const handleMaxBudgetChange = (param) => {
    setmaxBudget(param.target.value);
    setMaxBudgetErrorMessage("");
  };
  const handleContactNumberChange = (param) => {
    setContactNumber(/^\s/.test(param.target.value) ? "" : param.target.value);
    setContactNumberErrorMsg("");
  };
  const handleEmailChange = (param) => {
    setEmail(/^\s/.test(param.target.value) ? "" : param.target.value);
    setEmailErrorText("");
  };
  const handleUploadFileChange = (param) => {
    setUploadFile(param.target.files[0]);
  };
  const handleProjectDetailChange = (param) => {
    setProjectDetail(param);
    setProjectDetailErrorMessage("");
  };
  const handleHearChange = (param) =>{
    setHear(param);
  }
  const handleCommentChange = (e) => {
    setComment(e);
  };

  const handleContactStatusChange = (e) => {
    setContactStatus(e);
  };
  const handleContactResultChange = (e) => {
    setContactResult(e);
  };
  const validation = () => {
    let validate = true;
    let k = convertToRaw(projectDetail.getCurrentContent());
    const RegExp = /^\d*\.?\d{0,2}$/;
    const phoneRegEx = /^\+?\d[\d ]*$/;
    if (verifyMail(email) !== 0) {
      validate = false;
      setEmailErrorText("Enter a valid email");
    } else {
      setEmailErrorText("");
    }
    if (name == "") {
      validate = false;
      setNameErrorMessage("Enter Name");
    } else {
      setNameErrorMessage("");
    }

    if (k.blocks.every((b) => b.text.trim() === "")) {
      validate = false;
      setProjectDetailErrorMessage("Please Enter Project Detail");
    } else {
      setProjectDetailErrorMessage("");
    }
    if (RegExp.test(minBudget) == false) {
      validate = false;
      setMinBudgetErrorMessage(
        "you can enter only positive digit and two digit after Decimal"
      );
    } else {
      setMinBudgetErrorMessage("");
    }
    if (Number(maxBudget) < Number(minBudget)) {
      validate = false;
      setMaxBudgetErrorMessage("Please enter valid max budget value");
    } else if (RegExp.test(maxBudget) == false) {
      validate = false;
      setMaxBudgetErrorMessage(
        "you can enter only positive digit and two digit after Decimal"
      );
    } else {
      setMaxBudgetErrorMessage("");
    }
    if (contactNumber && !phoneRegEx.test(contactNumber)) {
        validate = false
        setContactNumberErrorMsg("Invalid phone number")
    }
    else {
        setContactNumberErrorMsg("");
    }
    return validate;
  };
  return (
    <AntModal
      centered
      className="attend_modal"
      footer={false}
      visible={showModal}
      onCancel={handleCancelButtonClick}
    >
      <Modal.Body>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            onSubmitClick();
          }}
        >
          <h3 className="mb-4">ADD Contact</h3>
          {resMsg ? (
            <div className="alert alert-success cm_top_fix">
              {" "}
              Contact Added Successfully{" "}
            </div>
          ) : (
            ""
          )}
          {resFailMsg ? (
            <div className="alert alert-danger cm_top_fix">
              {" "}
              Please Try Again{" "}
            </div>
          ) : (
            ""
          )}
          <div className="row">
            <div className="col-lg-6 col-md-6 mb-3">
              <label className="cm_bold">Client Name</label>
              <input
                maxLength="60"
                className="form-control cm_box"
                placeholder="Enter Name"
                type="text"
                value={name}
                onChange={handleNameChange}
              />
              <span className="errorText">{nameErrorMessage}</span>
            </div>
            <div className="col-lg-6 col-md-6 mb-3">
              <label className="cm_bold">Contact Number</label>
              <input
                maxLength={18}
                className="form-control cm_box"
                placeholder="Enter Contact Number"
                type="text"
                value={contactNumber}
                onChange={handleContactNumberChange}
              />
              <span className="errorText">{contactNumberErrorMsg}</span>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6 col-md-6 mb-3">
              <label className="cm_bold">Email</label>
              <input
                maxLength="60"
                className="form-control cm_box"
                placeholder="Please Enter Valid Email"
                type="text"
                value={email}
                onChange={handleEmailChange}
              />
              <span className="errorText">{emailErrorText}</span>
            </div>
            <div className="col-lg-6 col-md-6 mb-3">
              <label className="cm_bold">Budget ($)</label>
              <input
                maxLength="60"
                className="form-control cm_box mb-3"
                placeholder="Please Enter Min Amount"
                type="number"
                value={minBudget}
                onChange={handleMinBudgetChange}
              />
              <span className="errorText">{minBudgetErrorMessage}</span>

              <input
                maxLength="60"
                className="form-control cm_box"
                placeholder="Please Enter Max Amount"
                type="number"
                value={maxBudget}
                onChange={handleMaxBudgetChange}
              />
              <span className="errorText">{maxBudgetErrorMessage}</span>
            </div>
          </div>

          <div className="row">
            <div className={`form-group col-lg-6 col-md-6 mb-3 `}>
              <label className="cm_bold">Contact Result</label>

              <Select
                name="isAvailedPF"
                value={contactResult ? contactResult : "Result"}
                onChange={handleContactResultChange}
              >
                <Option value={1}>Win</Option>
                <Option value={2}>Loss</Option>
                <Option value={3}>Contact In Future</Option>
                <Option value={4}>Connection Not Established</Option>
              </Select>
            </div>

            <div className={`form-group col-lg-6 col-md-6 mb-3 `}>
              <label className="cm_bold">Contact Status</label>

              <Select
                name="isAvailedPF"
                value={contactStatus ? contactStatus : "Status"}
                onChange={handleContactStatusChange}
              >
                <Option value={1}>Hot</Option>
                <Option value={2}>Warm</Option>
                <Option value={3}>Cold</Option>
                <Option value={4}>Not Relevant</Option>
              </Select>
            </div>
          </div>

          <div className="row">
            <div className="form-group col-lg-12 col-md-12 mb-3">
              <label className="cm_bold">Project Details</label>
              <TextEditor
                editorState={projectDetail}
                onEditorStateChange={handleProjectDetailChange}
              />
              <span className="errorText">{projectDetailErrorMessage}</span>
            </div>
          </div>

          <div className="row">
            <div className="form-group col-lg-12 col-md-12 mb-3">
              <label className="cm_bold">Comment</label>
              <TextEditor
                editorState={comment}
                onEditorStateChange={handleCommentChange}
              />
            </div>
          </div>
          <div className="row">
            <div className="form-group col-lg-12 col-md-12 mb-3">
              <label className="cm_bold">Hear</label>
              <TextEditor
                editorState={hear}
                onEditorStateChange={handleHearChange}
                placeholder="e.g. Facebook, LinkedIn, Instagram"
              />
            </div>
          </div>
          <div className="row">
            <div className="form-group col-lg-12 col-md-12 mb-3">
              <label className="sub" for="upload-files">
                Upload File
              </label>
              <span className="form-control cm_hide_upload">
                <i className="fa fa-upload" aria-hidden="true"></i>
                <span className="cm_upload_name">
                  {uploadFile ? uploadFile.name : "Upload file"}
                </span>
                <input
                  type="file"
                  id="file"
                  multiple
                  accept="application/pdf,.doc, .docx"
                  onChange={handleUploadFileChange}
                />
              </span>
            </div>
            <div className="addCandidateButtonsDiv">
              <button
                onClick={handleCancelButtonClick}
                type="button"
                className="cancel_btn btn  buttonWidth"
              >
                Cancel
              </button>
              {loader ? (
                <DotLoader />
              ) : (
                <button className="btn btn-primary  buttonWidth" type="submit">
                  Submit
                </button>
              )}
            </div>
          </div>
        </form>
      </Modal.Body>
    </AntModal>
  );
};
const mapStateToProps = (state) => {
  return {
    loading: state.addCandidateByAdmin.loading,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    addContactByAdmin: (
      data,
      query,
      setShowModal,
      setErrorText,
      setResMsg,
      setFailResMsg,
      handleCancelButtonClick,
      setLoader
    ) => {
      dispatch(
        addContactByAdmin(
          data,
          query,
          setShowModal,
          setErrorText,
          setResMsg,
          setFailResMsg,
          handleCancelButtonClick,
          setLoader
        )
      );
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(AddContact);
